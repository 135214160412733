@use "../../abstracts/" as *;
#aboutPage {
  #textArea {
    width: 33vw;
    p {
      font-size: 1.25em;
    }
  }

  #timelineSection {
    @include html_tags("ul", -0.4);
    display: flex;
    flex-direction: column;
    position: relative;
    height: 450px;
    padding: 10px 0;
    margin: 3em 0;
    p {
      font-size: 0.9em;
      white-space: nowrap;
    }
  }

  ul {
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  li {
    flex-direction: row;
    color: $green;
    &::before {
      content: "";
      display: block;
      position: absolute;
      background-color: $magenta;
      height: 10px;
      width: 10px;
      border-radius: 5px;
      transform: translate(7.01em, 0.9em);
    }
  }

  .barDate {
    transform: translate(0.35em, 0em);
    @include clear_tags();
  }

  .barName {
    transform: translate(1em, 1.5em);
    @include clear_tags();
  }

  #timeLineBar {
    height: 100%;
    width: 0.25em;
    background-color: $green;
    position: absolute;
    transform: translate(7.2em, -10px);

    &::before,
    &::after {
      content: "";
      display: block;
      background-color: $green;
      height: 5px;
      width: 20px;
      transform: translate(-7.5px);
    }

    &::before {
      transform: translate(-7.5px);
    }

    &::after {
      transform: translate(-7.5px, 460px);
    }
  }
}

@media screen and (max-width: 1250px) {
  #aboutPage {
    #textArea {
      width: 95vw;
      h1 {
        font-size: 4.5em;
      }
    }
  }
}
