@use "../../abstracts/index" as *;
footer {
  background-color: $green_footer;
  display: flex;
  flex-direction: rows;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  h3 {
    color: $green;
    font-family: "Kanit", sans-serif;
    font-size: 150%;
    font-weight: 600;
    position: relative;
    transition: all $fast_trans;
    text-shadow: -1px -1px 0 $magenta_dark, 1px -1px 0 $magenta_dark,
      -1px 1px 0 $magenta_dark, 1px 1px 0 $magenta_dark;
  }

  p {
    font-weight: bold;
    text-align: center;
    transition: all $fast_trans;
    color: $magenta_low_sat;
  }

  .footerIcons {
    display: block;
    width: 48px;
    height: 48px;
    margin: 8px 16px;
    img {
      transition: all ease $fast_trans;
      height: 100%;
      width: 100%;
    }
  }

  // Resume and Contact Buttons
  #footerResumeAndContact {
    display: flex;
    height: 48px;
    width: 350px;
    a {
      position: relative;
      display: flex;
      justify-content: center;
      width: 50%;
      padding: auto;
      margin: 0 0.5em;
      border: solid;
      border-color: black;
      border-width: 2px;
      background-color: $magenta;
      flex-grow: 1;
      h3 {
        margin: auto;
      }
    }
    // Hover animation overlay
    a::before {
      content: "";
      position: absolute;
      background-color: $blue_low_sat;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 0;
      transition: all $med_trans;
    }
  }

  // Short text giving myself credit
  #footerMessage {
    margin: 0.5em 0;
    a {
      text-decoration: none;
      font-size: 1.25em;
      position: relative;
    }
    a::before {
      content: "";
      display: block;
      position: absolute;
      background-color: $magenta_low_sat;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      translate: 0 450%;
      width: 0;
      height: 20%;
      transition: all $fast_trans;
    }
  }
}

@media (hover: hover) {
  // Social Media Icons
  .footerIcons img {
    &:hover {
      transform: scale(1.25);
    }
    &:active {
      transform: scale(1);
    }
  }

  // Resume and Contact Page
  #footerResumeAndContact a {
    &:hover::before {
      width: 100%;
    }
    &:hover h3 {
      color: $magenta;
    }
  }

  // Final Credit Message
  #footerMessage a:hover::before {
    width: 100%;
  }
}

// Narrow Screen Switch
@media (max-width: 1250px) {
  footer {
    flex-direction: column;
  }
}
