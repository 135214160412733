// Main Tetradic (Dual Complimentary) Color Group
$green: hsla(105, 100%, 50%, 100%);
$magenta: hsla(285, 100%, 50%, 100%);
$red: hsla(0, 100%, 50%, 100%);
$blue: hsla(180, 100%, 50%, 100%);

// Reduced Saturation Group
$green_low_sat: hsla(105, 60%, 50%, 100%);
$magenta_low_sat: hsla(285, 50%, 50%, 100%);
$red_low_sat: hsla(0, 60%, 50%, 100%);
$blue_low_sat: hsla(180, 60%, 50%, 100%);

// Semi Opacity Group
$magenta_semi_clear: hsla(285, 50%, 50%, 50%);

// Low Opacity Group
$green_clear: hsla(105, 60%, 50%, 20%);
$magenta_clear: hsla(285, 50%, 50%, 20%);
$red_clear: hsla(0, 40%, 50%, 20%);
$blue_clear: hsla(180, 60%, 50%, 50%);

// Very Low Opacity Group
$green_very_clear: hsla(105, 60%, 50%, 10%);

// Dark Group
$green_dark: hsla(105, 60%, 15%, 100%);
$magenta_dark: hsla(285, 50%, 15%, 100%);
$red_dark: hsla(0, 40%, 50%, 15%);
$blue_dark: hsla(180, 60%, 15%, 100%);

// Off black for background
$green_black: hsla(105, 60%, 5%, 100%);

// Footer Background
$green_footer: hsla(105, 100%, 50%, 100%);

// Drop down Green
$green_clear: hsla(105, 40%, 20%, 95%);

//PRIMARYS
$primary_black: rgb(20, 20, 20);
$primary_neon: rgb(51, 255, 128);
$primary_navy: rgb(10, 46, 54);
$pGrey: grey;

// SECONDARY
$second_black: rgb(30, 30, 30);
$grey0: rgb(90, 90, 90);
$grey1: rgb(120, 120, 120);

// TERTIARY
$tertiary_black: rgb(100, 100, 100);

// FADDED
$fadded_neon: rgba(51, 255, 128, 0.6);

// Google Background
$google_grey: rgba(232, 235, 237, 1);
