@use "../../abstracts/" as *;
#notFoundPage {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin: 0 5vw;
  h1 {
    color: $green;
    font-size: 15vw;
  }
  p {
    margin: 1em 0 2em 0;
  }
}
