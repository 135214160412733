@use "../../abstracts/" as *;

.screenOverlay {
  display: none;
  width: 100%;
  background-color: $magenta_semi_clear;
  position: absolute;
  transition: all ease $med_trans;
  top: 0;
  flex-direction: column;
  z-index: 99;
  #screenOverlayBody {
    background-color: $magenta_low_sat;
    margin: 50px 5vw;
    border-radius: 50px;
    h1 {
      margin: auto 5vw;
    }
    p {
      margin: auto 5vw;
    }
    img {
      display: flex;
      width: 25vw;
      margin: 0 auto;
      border-radius: 5px;
    }
  }
}

// DropDown Menu Transitions
// Enter
.showOverlay-enter {
  display: flex;
  transform: translateY(-100%);
  opacity: 0;
}
.showOverlay-enter-active {
  display: flex;
  transform: translateY(0);
  opacity: 1;
}
.showOverlay-enter-done {
  display: flex;
  transform: translateY(0);
  opacity: 1;
}

// Exit
.showOverlay-exit {
  display: flex;
  transform: translateY(0);
  opacity: 1;
}
.showOverlay-exit-active {
  display: flex;
  transform: translateY(100%);
  opacity: 0;
}
.showOverlay-exit-done {
  transform: translateY(100%);
  display: none;
  opacity: 0;
}
