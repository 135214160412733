@use "../../abstracts/" as *;
#portfolioPage {
  flex-direction: column;

  h1 {
    text-align: center;
  }

  h2 {
    font-size: 2.5em;
  }

  h3 {
    font-size: 1.25em;
    color: $green;
    @include clear_tags;
  }

  // The entire section for listing projects
  #projectListArea {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  // A subsection of projectListArea for a Type of projects
  .projectTypeListArea {
    display: flex;
    flex-direction: column;
    background-color: $green_very_clear;
    border-radius: 25px;
    padding: 10px;
    margin: 25px auto;
  }

  // A subsection of projectTypeListArea that contains the items
  .projectTypeListItems {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  // An individual tile presenting a project
  .projectTile {
    @include html_tags("div");
    margin: 0 10px;
  }

  // The Icon and Button portion of a tile
  .tileBody {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    background-color: $green_low_sat;
    border-style: solid;
    border-radius: 10px;
    box-shadow: 3px 3px 1px $green_dark;
  }

  .projectIcon {
    width: 98%;
    height: 98%;
    border-radius: 10px;
  }

  // Overlay only visable during :hover
  .tileOverlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: $green_clear;
    opacity: 0;
    transition: all ease $med_trans;
  }

  button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 0;
    border-radius: 8px;
    padding: 0;
    transition: all ease $med_trans;
    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      border-radius: 10px;
      height: 50%;
      width: 80%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto auto;
    }
    &::before {
      background-color: $green_low_sat;
      transition: all ease $med_trans;
      z-index: 1;
    }
    &::after {
      background-color: $magenta_low_sat;
      transform: translate($ThreeD_offset, $ThreeD_offset);
      z-index: 0;
    }
    &:active {
      h2,
      &::before {
        transform: translate($ThreeD_offset, $ThreeD_offset);
      }
    }
    h2 {
      @include clear_tags;
      color: $magenta;
      position: relative;
      text-align: center;
      transition: all ease $med_trans;
      width: 75%;
      margin: auto auto;
      z-index: 2;
    }
  }
}

// ----------------------------------------------------------
// Multiple Hover Animations ONLY for devices that support it
// ----------------------------------------------------------

@media (hover: hover) {
  #portfolioPage .tileOverlay:hover {
    opacity: 1;
  }
}
