@font-face {
  font-family: Nanum Gothic Coding;
  src: url(../../public/fonts/NanumGothicCoding-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Nanum Gothic Coding;
  src: url(../../public/fonts/NanumGothicCoding-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Dancing Script";
  src: url(../../public/fonts/DancingScript-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url(../../public/fonts/Kanit-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
