@use "./abstracts/index" as *;
// Avoid Variance in broswer default styles

@include css-reset;

#root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 350px;
  width: auto;
}
